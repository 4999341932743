import { create } from "zustand";

import { createSelectors, scrollLock } from "@Shared/utils";

const SCROLL_LOCK_ID = "nav";

interface State {
    isOpen: boolean;
}

interface Action {
    open: () => void;
    close: () => void;
    toggle: (newState?: boolean) => void;
}

const defaultValue: State = {
    isOpen: false,
};

const useNavStateBase = create<State & Action>()(set => ({
    ...defaultValue,

    open: () => {
        set({ isOpen: true });

        scrollLock(true, SCROLL_LOCK_ID);
    },

    close: () => {
        set({ isOpen: false });

        scrollLock(false, SCROLL_LOCK_ID);
    },

    toggle: newState => {
        set(state => {
            const newIsOpen = typeof newState !== "undefined" ? newState : !state.isOpen;

            scrollLock(newIsOpen, SCROLL_LOCK_ID);

            return { isOpen: newIsOpen };
        });
    },
}));

export const useNavState = createSelectors(useNavStateBase);
