"use client";

import { FC, useRef } from "react";
import { useClickAway, useKeyPressEvent } from "react-use";

import { ElProps } from "@Shared/types";
import { Icon, Image, Text } from "@Shared/ui";
import { twcx } from "@Shared/utils";

import imageHands from "./images/hands.webp";

export type SuccessProps = ElProps<"div"> & {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    title: string;
    text?: string;
};

export const Success: FC<SuccessProps> = ({ className, isOpen, setIsOpen, title, text, ...restProps }) => {
    const refContainer = useRef<HTMLDivElement>(null);

    useClickAway(refContainer, () => (isOpen ? setIsOpen(false) : null));

    useKeyPressEvent("Escape", () => (isOpen ? setIsOpen(false) : null));

    return (
        <div
            className={twcx(
                "scroll-container-y fixed inset-0 z-10 flex p-5 transition-[opacity,transform]",
                "lg:p-10",
                {
                    "pointer-events-none scale-95 opacity-0": !isOpen,
                },
                className
            )}
            {...restProps}
        >
            <div
                ref={refContainer}
                className={twcx(
                    "relative m-auto flex min-h-[200px] w-full max-w-[440px] flex-col items-center justify-center gap-2 overflow-hidden rounded-xl bg-white px-3 py-12 text-center text-black shadow-[0_12px_30px_0_rgba(0,0,0,0.15)]",
                    "lg:min-h-[329px] lg:max-w-[598px] lg:gap-5 lg:rounded-[20px] lg:px-10"
                )}
            >
                <button
                    className={twcx(
                        "absolute right-1 top-1 z-10 flex size-10 items-center justify-center",
                        "lg:right-2 lg:top-2 lg:transition-colors lg:hover:text-blue-bright"
                    )}
                    aria-label="Close modal"
                    onClick={() => setIsOpen(false)}
                >
                    <Icon k="close" />
                </button>

                <Text className={twcx("flex items-center gap-2", "lg:gap-3")} as="h3" variant="h3" weight={600}>
                    {title}
                    <Image
                        className={twcx("w-8 shrink-0", "lg:w-16")}
                        src={imageHands}
                        alt="Hands"
                        width={64}
                        height={64}
                        sizes={{ default: "32px", lg: "64px" }}
                    />
                </Text>

                {text && (
                    <Text className="lg:max-w-[380px]" as="p" variant="p2" weight={500} color="gray-03">
                        {text}
                    </Text>
                )}
            </div>
        </div>
    );
};
