"use client";

import { FC } from "react";

import { useNavState } from "@Widgets/nav";
import { SingleHeader } from "@Shared/api";
import { MODALS } from "@Shared/config";
import { useModalState } from "@Shared/store";
import { ElProps } from "@Shared/types";
import { Button, Icon } from "@Shared/ui";
import { twcx } from "@Shared/utils";

export type ButtonsProps = ElProps<"div"> & {
    contactUsText: SingleHeader["buttonContactUsText"];
    burgerAriaLabel: SingleHeader["buttonBurgerAriaLabel"];
};

export const Buttons: FC<ButtonsProps> = ({ className, contactUsText, burgerAriaLabel, ...restProps }) => {
    const { open: modalOpen, close: modalClose, isOpen: modalIsOpen } = useModalState();
    const { isOpen, toggle } = useNavState();
    const isModalContactUsOpen = modalIsOpen(MODALS.contactUs);

    const handleContactUsClick = () => {
        (isModalContactUsOpen ? modalClose : modalOpen)(MODALS.contactUs);
    };

    const handleBurgerClick = () => toggle();

    return (
        <div className={twcx("ml-auto flex shrink-0 items-center gap-2", className)} {...restProps}>
            {!isOpen && (
                <Button
                    className={twcx({
                        "w-9 !p-0 lg:w-10": isModalContactUsOpen,
                    })}
                    variant="secondary"
                    onClick={handleContactUsClick}
                >
                    {isModalContactUsOpen ? <Icon k="close" /> : contactUsText}
                </Button>
            )}

            {!isModalContactUsOpen && (
                <Button
                    className={twcx("w-9 shrink-0 !p-0", "md:hidden lg:w-10")}
                    classNames={{
                        textIconContainer: twcx("transition-transform", { "-rotate-180": isOpen }),
                    }}
                    variant="secondary"
                    icon={isOpen ? "menuClose" : "menu"}
                    aria-label={burgerAriaLabel}
                    onClick={handleBurgerClick}
                />
            )}
        </div>
    );
};
