"use client";

import { FC, useEffect } from "react";
import { useKeyPressEvent } from "react-use";
import { usePathname } from "next/navigation";

import { useScreens } from "@Shared/hooks";
import { ElProps } from "@Shared/types";
import { Container as SharedContainer, Wrapper } from "@Shared/ui";
import { twcx } from "@Shared/utils";

import { useNavState } from "../store";

export type ContainerProps = ElProps<"nav">;

export const Container: FC<ContainerProps> = ({ className, children, ...restProps }) => {
    const pathname = usePathname();
    const { isOpen, close } = useNavState();
    const { isMd } = useScreens();

    useKeyPressEvent("Escape", () => (isOpen ? close() : null));

    useEffect(() => {
        if (isMd && isOpen) {
            close();
        }
    }, [isMd]);

    useEffect(() => {
        isOpen && close();
    }, [pathname]);

    return (
        <Wrapper
            className={twcx(
                "fixed inset-0 z-30 flex overflow-hidden transition-[opacity,transform]",
                "md:hidden",
                {
                    "pointer-events-none scale-95 opacity-0": !isOpen,
                },
                className
            )}
            as="nav"
            {...restProps}
        >
            <SharedContainer
                className={twcx(
                    "relative flex overflow-hidden rounded-xl bg-black pt-[var(--header-height)] text-white",
                    "before:pointer-events-none before:absolute before:left-0 before:right-0 before:top-[var(--header-height)] before:z-10 before:h-2 before:bg-gradient-to-b before:from-black",
                    "after:pointer-events-none after:absolute after:bottom-0 after:left-0 after:right-0 after:z-10 after:h-6 after:bg-gradient-to-t after:from-black"
                )}
            >
                {children}
            </SharedContainer>
        </Wrapper>
    );
};
