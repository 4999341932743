"use client";

import { FC, useEffect, useRef, useState } from "react";
import { useClickAway, useKeyPressEvent } from "react-use";

import { LOCALE_LINK_EN, LOCALE_LINK_RU } from "@Shared/config";
import { DEFAULT_LANGUAGE } from "@Shared/i18n";
import { ElProps } from "@Shared/types";
import { Button, Icon, Text } from "@Shared/ui";
import { twcx } from "@Shared/utils";

type LanguagesPropsClassNames = {
    button?: string;
    list?: string;
    listButton?: string;
};

export type LanguagesProps = ElProps<"div"> & {
    classNames?: LanguagesPropsClassNames;
};

export const Languages: FC<LanguagesProps> = ({ className, classNames, ...restProps }) => {
    const refComponent = useRef<HTMLDivElement>(null);
    const refList = useRef<HTMLDivElement>(null);

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [listPosition, setListPosition] = useState<"top" | "bottom">("bottom");

    const isEn = DEFAULT_LANGUAGE === "en";
    const isRu = DEFAULT_LANGUAGE === "ru";

    const handleClose = () => (isOpen ? setIsOpen(false) : null);
    const handleButtonClick = () => setIsOpen(state => !state);

    useClickAway(refComponent, handleClose);
    useKeyPressEvent("Escape", handleClose);

    useEffect(() => {
        if (!refList.current || !isOpen) {
            return;
        }

        refList.current.style.top = "100%";
        refList.current.style.bottom = "auto";
        refList.current.style.marginTop = "8px";
        refList.current.style.marginBottom = "0px";

        setListPosition(refList.current.getBoundingClientRect().bottom > innerHeight ? "top" : "bottom");

        refList.current.removeAttribute("style");
    }, [isOpen]);

    if ((isEn && !LOCALE_LINK_RU) || (isRu && !LOCALE_LINK_EN)) {
        return;
    }

    return (
        <div ref={refComponent} className={twcx("relative", className)} {...restProps}>
            <button
                className={twcx(
                    "flex items-center gap-1 text-white",
                    "lg:transition-colors lg:hover:text-blue-light",
                    classNames?.button
                )}
                type="button"
                onClick={handleButtonClick}
            >
                <Icon k="globe" size="sm" />

                <Text className="lg:text-p2" weight={700} uppercase>
                    {DEFAULT_LANGUAGE}
                </Text>

                <Icon className={twcx("transition-transform", { "-scale-y-100": isOpen })} k="angleDownSm" />
            </button>

            <div
                ref={refList}
                className={twcx(
                    "absolute left-0 flex flex-col gap-1 transition-opacity",
                    {
                        "pointer-events-none opacity-0": !isOpen,
                        "bottom-full mb-2": listPosition === "top",
                        "top-full mt-2": listPosition === "bottom",
                    },
                    classNames?.list
                )}
            >
                {isRu && LOCALE_LINK_EN && (
                    <Button
                        className={classNames?.listButton}
                        variant="dropdown"
                        href={LOCALE_LINK_EN}
                        simple
                        onClick={handleClose}
                    >
                        En
                    </Button>
                )}

                {isEn && LOCALE_LINK_RU && (
                    <Button
                        className={classNames?.listButton}
                        variant="dropdown"
                        href={LOCALE_LINK_RU}
                        simple
                        onClick={handleClose}
                    >
                        Ru
                    </Button>
                )}
            </div>
        </div>
    );
};
