"use client";

import { FC } from "react";

import { ComponentSharedLink } from "@Shared/api";
import { ElProps } from "@Shared/types";
import { Link } from "@Shared/ui";
import { twcx } from "@Shared/utils";

import { useNavState } from "../store";

export type LinksProps = ElProps<"div"> & {
    items: ComponentSharedLink[];
};

export const Links: FC<LinksProps> = ({ className, items, ...restProps }) => {
    const { close } = useNavState();

    if (!items.length) {
        return;
    }

    return (
        <div className={twcx("flex flex-col gap-6 text-h3-d font-semibold", "lg:text-h3", className)} {...restProps}>
            {items.map((link, index) => (
                <Link
                    key={index + link.text}
                    className="block lg:transition-colors lg:hover:text-blue-light"
                    href={link.link}
                    target={link.blank ? "_blank" : undefined}
                    onClick={close}
                >
                    {link.text}
                </Link>
            ))}
        </div>
    );
};
