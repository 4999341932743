"use client";

import { FC, useEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";

import { SingleFooter } from "@Shared/api";
import { MODALS } from "@Shared/config";
import { useScreens } from "@Shared/hooks";
import { useModalState } from "@Shared/store";
import { ElProps, Nullable } from "@Shared/types";
import { Image, Text } from "@Shared/ui";
import { getImageSrcFromApiData, twcx } from "@Shared/utils";

const MOUSE_PROP_KEYS = {
    x: "--mouse-x",
    y: "--mouse-y",
};

export type TitleWithButtonProps = ElProps<"div"> & {
    title: SingleFooter["title"];
    buttonText: SingleFooter["buttonContactUsText"];
    buttonImage?: SingleFooter["buttonContactUsImage"];
};

export const TitleWithButton: FC<TitleWithButtonProps> = ({
    className,
    title,
    buttonText,
    buttonImage,
    ...restProps
}) => {
    const refContainer = useRef<Nullable<HTMLDivElement>>(null);
    const refButton = useRef<Nullable<HTMLButtonElement>>(null);

    const [buttonIsVisible, setButtonIsVisible] = useState<boolean>(false);

    const { isLg } = useScreens();
    const { open } = useModalState();
    const { ref: refInView, inView } = useInView();

    const handleModalOpen = () => open(MODALS.contactUs);

    useEffect(() => {
        if (!isLg) {
            setButtonIsVisible(false);

            return;
        }

        const setMousePosition = (e: MouseEvent) => {
            refContainer.current?.style.setProperty(
                MOUSE_PROP_KEYS.x,
                `${e.clientX - (refButton.current?.offsetWidth ?? 0) / 2}px`
            );

            refContainer.current?.style.setProperty(
                MOUSE_PROP_KEYS.y,
                `${e.clientY - (refButton.current?.offsetHeight ?? 0) / 2}px`
            );
        };

        const handleMouseEnter = (e: MouseEvent) => {
            setMousePosition(e);
            setButtonIsVisible(true);
        };

        const handleMouseMove = (e: MouseEvent) => {
            setMousePosition(e);
            setButtonIsVisible(true);
        };

        const handleMouseLeave = () => setButtonIsVisible(false);

        refContainer.current?.addEventListener("mouseenter", handleMouseEnter);
        refContainer.current?.addEventListener("mousemove", handleMouseMove);
        refContainer.current?.addEventListener("mouseleave", handleMouseLeave);

        return () => {
            refContainer.current?.removeEventListener("mouseenter", handleMouseEnter);
            refContainer.current?.removeEventListener("mousemove", handleMouseMove);
            refContainer.current?.removeEventListener("mouseleave", handleMouseLeave);

            refContainer.current?.style.removeProperty(MOUSE_PROP_KEYS.x);
            refContainer.current?.style.removeProperty(MOUSE_PROP_KEYS.y);

            setButtonIsVisible(false);
        };
    }, [isLg]);

    useEffect(() => {
        if (!inView && isLg) {
            setButtonIsVisible(false);
        }
    }, [inView, isLg]);

    return (
        <div className={twcx("pb-[38px] pt-10", "lg:pb-[120px] lg:pt-[100px]", className)} {...restProps}>
            <div
                ref={el => {
                    refContainer.current = el;
                    refInView(el);
                }}
                className={twcx(
                    "relative m-auto flex max-w-[1300px] flex-col gap-6",
                    "lg:cursor-pointer lg:select-none lg:items-center lg:text-center"
                )}
                onClick={isLg ? handleModalOpen : undefined}
            >
                <Text as="h2" variant="max" weight={600}>
                    {title}
                </Text>

                <button
                    ref={refButton}
                    className={twcx(
                        "relative z-0 flex size-[192px] shrink-0 select-none items-center justify-center overflow-hidden rounded-full bg-white text-center",
                        "lg:pointer-events-none lg:fixed lg:left-0 lg:top-0 lg:size-[256px] lg:opacity-0 lg:transition-opacity",
                        {
                            "lg:opacity-100": buttonIsVisible && inView,
                        }
                    )}
                    style={{
                        transform: `translate3d(var(${MOUSE_PROP_KEYS.x}, 0), var(${MOUSE_PROP_KEYS.y}, 0), 0px)`,
                    }}
                    type="button"
                    onClick={handleModalOpen}
                >
                    <Text as="span" weight={700} color="black" uppercase>
                        {buttonText}
                    </Text>

                    {buttonImage?.data && (
                        <Image
                            className="absolute left-0 top-0 -z-10 size-full object-cover"
                            src={getImageSrcFromApiData(buttonImage.data.attributes)}
                            fill
                            sizes={{
                                default: "192px",
                                lg: "256px",
                            }}
                            role="presentation"
                        />
                    )}
                </button>
            </div>
        </div>
    );
};
