"use client";

import { FC, useEffect, useRef, useState } from "react";
import { useWindowSize } from "react-use";

import { useNavState } from "@Widgets/nav";
import { MODALS } from "@Shared/config";
import { useModalState } from "@Shared/store";
import { ElProps } from "@Shared/types";
import { AnimationFadeIn, Container as SharedContainer, Wrapper } from "@Shared/ui";
import { twcx } from "@Shared/utils";

const SCROLL_POSITION = 50;

export type ContainerProps = ElProps<"header"> & {
    isFixed?: boolean;
};

export const Container: FC<ContainerProps> = ({ className, isFixed = true, children, ...restProps }) => {
    const refComponent = useRef<HTMLDivElement>(null);

    const [isScrolled, setIsScrolled] = useState<boolean>(false);

    const { width } = useWindowSize();
    const { isOpen } = useNavState();
    const { isOpen: modalIsOpen } = useModalState();

    const isModalContactUsOpen = modalIsOpen(MODALS.contactUs);

    useEffect(() => {
        if (!isFixed) {
            return;
        }

        let isScrolledInner: boolean = isScrolled;

        const handleScroll = () => {
            if (scrollY > SCROLL_POSITION && !isScrolledInner) {
                setIsScrolled(true);

                isScrolledInner = true;
            } else if (scrollY <= SCROLL_POSITION && isScrolledInner) {
                setIsScrolled(false);

                isScrolledInner = false;
            }
        };

        handleScroll();

        window.addEventListener("scroll", handleScroll);

        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    useEffect(() => {
        if (!refComponent.current) {
            return;
        }

        document.documentElement.style.setProperty("--header-height", `${refComponent.current.offsetHeight}px`);

        document.documentElement.style.setProperty(
            "--header-scroll-offset",
            isFixed ? `${refComponent.current.offsetHeight}px` : ""
        );
    }, [width]);

    return (
        <Wrapper
            ref={refComponent}
            className={twcx(
                "fixed left-0 right-0 top-0 z-40",
                {
                    absolute: !isFixed && !isOpen && !isModalContactUsOpen,
                },
                className
            )}
            as="header"
            {...restProps}
        >
            <SharedContainer
                className={twcx("overflow-hidden rounded-xl text-white transition-colors", {
                    "bg-black": isScrolled,
                })}
            >
                <AnimationFadeIn type="fadeInTop" offset={0}>
                    <div
                        className={twcx(
                            "relative flex items-center gap-4 py-3",
                            "before:absolute before:bottom-1 before:left-0 before:right-0 before:h-px before:bg-white before:bg-opacity-30 before:transition-opacity",
                            "md:gap-6",
                            "lg:gap-10 lg:py-4",
                            { "before:opacity-0": isScrolled && !isOpen && !isModalContactUsOpen }
                        )}
                    >
                        {children}
                    </div>
                </AnimationFadeIn>
            </SharedContainer>
        </Wrapper>
    );
};
