"use client";

import { FC } from "react";

import { ComponentFormField } from "@Shared/api";
import { ElProps } from "@Shared/types";
import { Button, Text } from "@Shared/ui";
import { twcx } from "@Shared/utils";

const VALUE_SPLITTER = ", ";

export type InterestsProps = ElProps<"div"> & {
    field: ComponentFormField;
    value: string;
    setValue: (value: string | ((v: string) => string)) => void;
};

export const Interests: FC<InterestsProps> = ({ className, field, value, setValue, ...restProps }) => {
    const handleButtonClick = (val: string) => {
        setValue(prev => {
            const prevArr = prev ? prev.split(VALUE_SPLITTER) : [];

            if (prevArr.includes(val)) {
                return prevArr.filter(v => v !== val).join(VALUE_SPLITTER);
            } else {
                return [...prevArr, val].join(VALUE_SPLITTER);
            }
        });
    };

    return (
        <div className={twcx("flex flex-col gap-3", className)} {...restProps}>
            <input type="hidden" name={field.name} value={value} required={field.required} />

            {field.label && (
                <Text variant="p2" weight={600} color="blue-light">
                    {field.label}
                </Text>
            )}

            <div className="flex flex-wrap gap-2">
                {field.values?.map((v, vIndex) => {
                    const isString = typeof v === "string";
                    const label = isString ? v : v.label;
                    const val = isString ? v : v.value;

                    return (
                        <Button
                            key={vIndex}
                            variant="tab"
                            active={value.includes(val)}
                            onClick={() => handleButtonClick(val)}
                        >
                            {label}
                        </Button>
                    );
                })}
            </div>
        </div>
    );
};
