import { FC } from "react";

import { getCommonData } from "@Shared/api";
import { GroupContactsSocials, Text } from "@Shared/ui";

import { Container, ContainerProps } from "./Container";
import { Links } from "./Links";

export type NavProps = ContainerProps;

export const Nav: FC<NavProps> = async props => {
    const { header, navigation } = await getCommonData();

    return (
        <Container {...props}>
            <div className="scroll-container-y flex flex-1 flex-col gap-8 pb-6 pt-2">
                <div className="flex flex-col gap-3">
                    {navigation.title && (
                        <Text as="h2" variant="p2" weight={700} color="gray-04" uppercase>
                            {navigation.title}
                        </Text>
                    )}

                    <Links
                        items={[
                            ...(navigation.links?.data ?? []),
                            ...(navigation.linksFromHeader ? (header.links?.data ?? []) : []),
                        ].map(link => link.attributes.link)}
                    />
                </div>

                <GroupContactsSocials className="mt-auto" />
            </div>
        </Container>
    );
};
