import { initReactI18next } from "react-i18next/initReactI18next";
import { createInstance } from "i18next";
import resourcesToBackend from "i18next-resources-to-backend";

import { DEFAULT_LANGUAGE, DEFAULT_NS, getOptions } from "./config";

const initI18next = async () => {
    const i18nInstance = createInstance();
    await i18nInstance
        .use(initReactI18next)
        .use(
            resourcesToBackend(
                (language: string, namespace: string) => import(`@/locales/${language}/${namespace}.json`)
            )
        )
        .init(getOptions());

    return i18nInstance;
};

export async function useServerTranslation(ns: string = DEFAULT_NS) {
    const i18nextInstance = await initI18next();

    return {
        t: i18nextInstance.getFixedT(DEFAULT_LANGUAGE, ns),
        i18n: i18nextInstance,
    };
}
